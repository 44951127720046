<template>

    <footer class="rounded-lgbg-gray-900 m-4 justify-center">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
            <hr class="my-6 border-gray-700 sm:mx-auto lg:my-8" />
    
            
            <div class="flex justify-center">
                
                <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-400 sm:mb-0 gap-10">
                    <li>
                        <a href="https://raw.githubusercontent.com/redtrib3/rctf-server/main/LICENSE" class="hover:text-white">License</a>
                    </li>
                    <li>
                        <a href="https://github.com/redtrib3/rctf-server" class="hover:text-white">View source</a>
                    </li>
                    <li>
                        <a href="/sitemap.xml" class="hover:text-white">Sitemap</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>

</template>

<script>

export default {
    name: 'FooterComp'
}

</script>