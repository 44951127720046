<template>
    <div class="relative" @mouseover="showCopyButton=true" @mouseleave="showCopyButton=false">
        <button v-if="showCopyButton" @click="copyCode" class="animate-fadeIn-quick absolute top-0 right-0 mt-2 mr-2 px-2 py-1 bg-slate-400 hover:bg-slate-200 rounded">
            <svg v-if="copied" class="w-3 h-3" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m14.7071 3.00001-9.20709 9.20709-5.207102-5.20709.707102-.7071 4.5 4.49999 8.49999-8.49999z" fill="#000" fill-rule="evenodd"/></svg>
            <svg v-else class="w-3 h-3 fill-current text-black" title="Copy snippet" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m6 19v2c0 .621.52 1 1 1h2v-1.5h-1.5v-1.5zm7.5 3h-3.5v-1.5h3.5zm4.5 0h-3.5v-1.5h3.5zm4-3h-1.5v1.5h-1.5v1.5h2c.478 0 1-.379 1-1zm-1.5-1v-3.363h1.5v3.363zm0-4.363v-3.637h1.5v3.637zm-13-3.637v3.637h-1.5v-3.637zm11.5-4v1.5h1.5v1.5h1.5v-2c0-.478-.379-1-1-1zm-10 0h-2c-.62 0-1 .519-1 1v2h1.5v-1.5h1.5zm4.5 1.5h-3.5v-1.5h3.5zm3-1.5v-2.5h-13v13h2.5v-1.863h1.5v3.363h-4.5c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v4.5h-3.5v-1.5z" fill-rule="nonzero"/></svg>
        </button>
        <pre class="overflow-auto bg-gray-800 rounded-lg p-4 "><code class="text-slate-300" v-html="code"></code></pre>
    </div>
  </template>
  
  <script>
  export default {
    name: 'codeSnippet',
    data(){
        return {
            showCopyButton: false,
            copied: false
        }
    },
    props: {
      code: String
    },

    methods: {
        copyCode(){
            navigator.clipboard.writeText(this.code);
            this.copied = true;

            setTimeout(()=>{
                this.copied = false;
            }, 2000);

        }
    }
  }
  </script>
  

  