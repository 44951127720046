<template>

    <div class="max-w-xl m-auto flex justify-center">
        <div class="max-w-xl m-auto flex justify-center">
        <main class="absolute inset-0 flex items-center justify-center">
            <h1 class="lg:text-5xl text-3xl font-anonymouspro">
                <span class="text-lime-400 hover:text-red-400 cursor-default" >[redtrib3</span>

                <span class="text-yellow-200">@</span>
                <span class="font-extrabold text-cyan-400">parrot]</span>
                <span class="hidden md:inline">:~$ </span>
                <span class="hidden md:inline animate-ping font-extrabold">_</span>
            </h1>            
            
        </main>
        </div>

    </div>

    <div class="max-w-xl m-auto flex justify-center items-center absolute text-md font-anonymouspro inset-0 mt-20 pl-4">
        <a href="https://github.com/redtrib3" class="pt-10 pr-4 lg:pr-10 font-bold hover:text-orange-400" target="_blank">[ Github ]</a>
        <a href="https://www.linkedin.com/in/anirudh-krishnaprasad" class="pt-10 pr-4 lg:pr-10 font-bold hover:text-blue-400" target="_blank">[ Linkedin ]</a>
        <a href="mailto:redtrib3@protonmail.com" class="pt-10 pr-4 lg:pr-10 hover:text-purple-400 font-bold" >[ Email ]</a>
    </div>
</template>

<script>
export default {
    name: 'Homepage'

}
</script>


 <!-- .container {
  max-width: 1000px;
  margin: auto;
  height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}  -->

